import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DataService } from 'src/app/core/services/data.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-update-configuration-manager',
  templateUrl: './update-configuration-manager.component.html',
  styleUrls: ['./update-configuration-manager.component.scss'],
})
export class UpdateConfigurationManagerComponent implements OnInit {
  skuList = [];
  skuForm: FormGroup;
  schema = '';
  isDisabled = true;
  filteredOptions: Observable<any[]>;
  @ViewChild('jsonSchema', { static: false }) jsonSchema: ElementRef;
  schemaValidationMsg = '';
  schemaUploadMsg = '';
  invalidSchemaMsg = '';

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    public dataService: DataService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public utilService: UtilService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.utilService.checkIfLoggedIn();
    this.skuForm = new FormGroup({
      skuList: new FormControl('', [Validators.required]),
      revValue: new FormControl('', [Validators.required]),
    });

    //
    const skuListControl = this.skuForm.get('skuList');
    this.filteredOptions = skuListControl.valueChanges.pipe(
      startWith(''),
      map((val) => this._filter(val))
    );

    this.translate
      .stream([
        'CONFIG_MANAGER.SCHEMA_VALIDATION_SUCCESS',
        'CONFIG_MANAGER.UPDATE.SCHEMA_UPLOAD_SUCCESS',
        'CONFIG_MANAGER.UPDATE.INVALID_SCHEMA',
      ])
      .subscribe((res) => {
      //  console.log(res);
        this.schemaValidationMsg =
          res['CONFIG_MANAGER.SCHEMA_VALIDATION_SUCCESS'];
        this.schemaUploadMsg =
          res['CONFIG_MANAGER.UPDATE.SCHEMA_UPLOAD_SUCCESS'];
        this.invalidSchemaMsg = res['CONFIG_MANAGER.UPDATE.INVALID_SCHEMA'];
      });

    // call API to fetch SKU list
    this.fetchSKU();
  }

  private _filter(value: string): string[] {
    const filterValue = value;

    return this.skuList.filter((option) => option.includes(filterValue));
  }

  // get SKU list
  fetchSKU() {
    this.spinner.show();
    this.dataService.fetchSKUList().subscribe(
      (res) => {
        if (res && res.length > 0) {
          this.skuList = res;
        } else {
          this.skuList = [];
        }

        this.spinner.hide();
      },
      () => {
        this.skuList = [];
        this.spinner.hide();
      }
    );
  }

  // get schema
  fetchSchema() {
    this.spinner.show();

    const reqbody = {
      sku: `${this.skuForm.get('skuList').value}`,
      _it: this.skuForm.get('revValue').value,
    };
    let message = '';
    this.dataService.fetchSchema(reqbody).subscribe(
      (res) => {
        // if (res && res.hasOwnProperty('body')) {
        // const response =
        //   res.body && this.isJsonString(res.body) ? JSON.parse(res.body) : '';

        if (res !== '' && res !== null && res !== 'Failed') {
          this.schema = res;
        } else {
          message = 'An error occurred while fetching schema.';
        }
        // } else {
        //   message = 'An error occurred while fetching schema.';
        // }

        this.openModalPopUp(message, false);

        this.spinner.hide();
      },
      (err) => {
        message =
          err.hasOwnProperty('error') &&
          err.error &&
          err.error.hasOwnProperty('message') &&
          err.error.message
            ? err.error.message
            : 'An error occurred while fetching schema.';
        this.openModalPopUp(message, false);
        this.spinner.hide();
      }
    );
  }

  // validate JSON
  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  // validate schema - only JSON
  validate() {
    this.spinner.show();
    let isValidJSONYAML = null;
    const schema = this.jsonSchema.nativeElement.value;
    isValidJSONYAML = this.isJsonString(schema);

    if (isValidJSONYAML) {
      this.isDisabled = false;
      this.openModalPopUp(this.schemaValidationMsg, '');
    } else {
      this.isDisabled = true;
      this.openModalPopUp(this.invalidSchemaMsg, false);
    }
    this.spinner.hide();
  }

  // call API to update schema
  update() {
    this.spinner.show();
    let message = '';
    const schema = this.jsonSchema.nativeElement.value;
    let resetForm = false;
    this.dataService.updataSchema(schema).subscribe(
      (res) => {
        // if (res && res.hasOwnProperty('body')) {
        // const response =
        //   res.body && this.isJsonString(res.body) ? JSON.parse(res.body) : '';
        if (
          res !== '' &&
          res !== null &&
          res.hasOwnProperty('status') &&
          res.status === 'Success'
        ) {
          message = this.schemaUploadMsg; // 'Schema updated successfully.';
          resetForm = true;
        } else {
          message = 'There was an error while updating the schema.';
          resetForm = false;
        }
        // } else {
        //   message = 'There was an error while updating the schema.';
        //   resetForm = false;
        // }

        this.spinner.hide();

        this.openModalPopUp(message, resetForm);
      },
      (err) => {
        message =
          err.hasOwnProperty('error') &&
          err.error &&
          err.error.hasOwnProperty('message') &&
          err.error.message
            ? err.error.message
            : 'An unexpected error occurred.';
        this.openModalPopUp(message, false);
        this.spinner.hide();
      }
    );
  }

  goToConfigManager() {
    this.router.navigate(['/configuration-manager']);
  }

  // modal pop-ups for showing warning/error messaages
  openModalPopUp(bodyText, resetForm) {
    if (bodyText) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        message: bodyText,
      };

      if (resetForm) {
        this.dialog
          .open(ModalComponent, dialogConfig)
          .afterClosed()
          .subscribe((res1) => {
            this.clearForm(this.skuForm);
          });
      } else {
        this.dialog.open(ModalComponent, dialogConfig);
      }
    }
  }

  // reset form and the schema content
  clearForm(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach((key) => {
      form.get(key).setErrors(null);
    });

    this.jsonSchema.nativeElement.value = '';
    this.schema = '';
    this.isDisabled = true;
  }
}
