import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UtilService } from "src/app/core/services/util.service";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  searchParam = "";
  searchValue = "";
  loadComponent = false;
  displayError = false;
  errorMsg = "";
  searchObj;
  searchList = [];
  placeholder = "";
  invalidSerialMsg = "";
  invalidUUIDMsg = "";
  invalidMACMsg = "";
  invalidEmailMsg = "";
  errMsg = "";
  placeholderMsg = "";

  constructor(
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    //  loginStatus = 1 - redirect to search
    //  else - redirect to login
    this.utilService.checkIfLoggedIn();

    this.translate
      .stream([
        "SEARCH.INVALID_SERIAL",
        "SEARCH.INVALID_UUID",
        "SEARCH.INVALID_MAC",
        "SEARCH.INVALID_EMAIL",
        "SEARCH.ERR_MSG",
        "HEADER.PLACEHOLDER",
      ])
      .subscribe((res) => {
        console.log(res);
        this.invalidSerialMsg = res["SEARCH.INVALID_SERIAL"];
        this.invalidUUIDMsg = res["SEARCH.INVALID_UUID"];
        this.invalidMACMsg = res["SEARCH.INVALID_MAC"];
        this.invalidEmailMsg = res["SEARCH.INVALID_EMAIL"];
        this.errMsg = res["SEARCH.ERR_MSG"];
        this.placeholderMsg = res["HEADER.PLACEHOLDER"];
      });

    this.searchList = [
      { name: "MAC", format: "F0:FE:6B:69:4F:C0" },
      {
        name: "UUID",
        format: "1110401e-55d4-4360-8273-2a199c51f7d4",
      },
      { name: "Email", format: "johndoe@test.com" },
      { name: "Serial", format: "112345612312311211234567" },
    ];

    // check if user searched from menu
    this.searchParam = this.activatedRoute.snapshot.queryParams.hasOwnProperty(
      "searchParam"
    )
      ? this.activatedRoute.snapshot.queryParams.searchParam
      : "MAC";
    this.searchValue = this.activatedRoute.snapshot.queryParams.hasOwnProperty(
      "searchVal"
    )
      ? this.activatedRoute.snapshot.queryParams.searchVal
      : "";

    if (this.searchValue !== "") {
      this.searchObj = {
        key: this.searchParam,
        value: this.searchValue,
      };
      this.goToSearchDetails(this.searchObj);
    }

    this.changePlaceholder();
  }

  goToSearchDetails(objSearch) {
    this.displayError = false;
    this.errorMsg = "";

    if (
      objSearch.value &&
      objSearch.value !== "" &&
      !objSearch.value.toLowerCase().includes("<script>")
    ) {
      if (objSearch.key === "Email") {
        // check for email
        /* tslint:disable */
        const pattern =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!pattern.test(objSearch.value)) {
          this.displayError = true;
          this.loadComponent = false;
          this.errorMsg = this.invalidEmailMsg; // 'Please enter valid email address.';
          return;
        }
      } else if (objSearch.key.toLowerCase() === "mac") {
        if (!(objSearch.value.length === 12 || objSearch.value.length === 17)) {
          this.displayError = true;
          this.loadComponent = false;
          this.errorMsg = this.invalidMACMsg; //'Please enter valid MAC ID.';
          return;
        }
      } else if (objSearch.key.toLowerCase() === "uuid") {
        if (!(objSearch.value.length === 36 || objSearch.value.length === 32)) {
          this.displayError = true;
          this.loadComponent = false;
          this.errorMsg = this.invalidUUIDMsg; //'Please enter valid UUID.';
          return;
        }
      } else if (objSearch.key.toLowerCase() === "serial") {
        const match = /^([A-Za-z0-9]+-)*[A-Za-z0-9]+$/;
        if (!match.test(objSearch.value)) {
          this.displayError = true;
          this.loadComponent = false;
          this.errorMsg = this.invalidSerialMsg; //'Please enter valid Serial No.';
          return;
        }
      } else {
      }

      if (objSearch.key === "Email") {
        objSearch.value = encodeURIComponent(objSearch.value); // the UTF-8 encoding of special characters
      }
      this.searchObj = {
        key: objSearch.key.toLowerCase(),
        value: objSearch.value,
      };
      this.loadComponent = true;
    } else {
      this.loadComponent = false;
      this.displayError = true;
      this.errorMsg = this.errMsg; //'Please enter value to search.';
    }
  }

  changePlaceholder() {
    this.placeholder = `${this.placeholderMsg} ${
      this.searchList.filter((item) => item.name === this.searchParam)[0].format
    }`;

    this.placeholder;
  }
}
