import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SearchComponent } from './search/search.component';
import { DeviceInformationComponent } from './device-information/device-information.component';
import { SearchDetailsComponent } from './search-details/search-details.component';
import { DebuggerComponent } from './debugger/debugger.component';
import { ChartsModule } from 'ng2-charts';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { HeaderComponent } from './header/header.component';
import { MenubarComponent } from './menubar/menubar.component';
import { DeviceMonitorComponent } from './device-monitor/device-monitor.component';
// import {
//   NgxMatDatetimePickerModule,
//   NgxMatTimepickerModule,
//   NgxMatNativeDateModule,
// } from '@angular-material-components/datetime-picker';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { ModalComponent } from './modal/modal.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EventLogAlarmComponent } from './event-log-alarm/event-log-alarm.component';
import { TelemetryChartComponent } from './telemetry-chart/telemetry-chart.component';
import { OtaHistoryComponent } from './ota-history/ota-history.component';
import { UserComponent } from './user/user.component';
import { ModalChartComponent } from './modal-chart/modal-chart.component';
import { BlogComponent } from './blog/blog.component';
import { BlogAdminComponent } from './blog-admin/blog-admin.component';
import { QuillModule } from 'ngx-quill';
import { RepairInfoComponent } from './repair-info/repair-info.component';
import { ConfigurationManagerComponent } from './configuration-manager/configuration-manager.component';
import { CreateConfigurationManagerComponent } from './create-configuration-manager/create-configuration-manager.component';
import { UpdateConfigurationManagerComponent } from './update-configuration-manager/update-configuration-manager.component';
import { MatAutocompleteModule } from '@angular/material';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ModalBlogComponent } from './modal-blog/modal-blog.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    SearchComponent,
    DeviceInformationComponent,
    SearchDetailsComponent,
    DebuggerComponent,
    FilterDialogComponent,
    HeaderComponent,
    MenubarComponent,
    DeviceMonitorComponent,
    ResetPasswordComponent,
    PasswordStrengthComponent,
    ModalComponent,
    EventLogAlarmComponent,
    TelemetryChartComponent,
    OtaHistoryComponent,
    UserComponent,
    ModalChartComponent,
    BlogComponent,
    BlogAdminComponent,
    RepairInfoComponent,
    ConfigurationManagerComponent,
    CreateConfigurationManagerComponent,
    UpdateConfigurationManagerComponent,
    ModalBlogComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    ChartsModule,
    MatMenuModule,
    // NgxMatDatetimePickerModule,
    // NgxMatTimepickerModule,
    MatSortModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatRadioModule,
    QuillModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [],
})
export class SMTModule {}
