import {
  Component,
  EventEmitter,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { UtilService } from '../../core/services/util.service';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss'],
})
export class MenubarComponent implements OnInit {
  public isSearchBar = false;
  showSearchIcon = true;
  searchValue = '';
  searchParam = '';
  url: string = environment.deviceMonitorURL;
  isDeviceInfo = false;
  isDebugger = false;
  user: string;
  searchList = [];
  placeholder = '';
  temperature; // default value
  userScope = '';
  regionName = environment.regionName;
  @Output() tempChangeEvent = new EventEmitter<string>();
  placeholderMsg = '';
  // menuList = [
  //   {
  //     title: `News & Updates`,
  //     condition: this.regionName !== 'Frankfurt',
  //   },
  //   {
  //     title: `Search`,
  //     condition: this.regionName !== 'Frankfurt',
  //   },
  //   {
  //     title: `Debugger`,
  //     condition:
  //       this.isDebugger &&
  //       this.userScope === 'sm' &&
  //       this.regionName !== 'Frankfurt',
  //   },
  //   {
  //     title: `Device Information`,
  //     condition: this.isDeviceInfo && this.regionName !== 'Frankfurt',
  //   },
  //   {
  //     title: `Configuration Manager`,
  //     condition: this.regionName === 'Frankfurt',
  //   },
  //   {
  //     title: `Device Monitor`,
  //     condition: true,
  //   },
  // ];

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public utilService: UtilService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    // dont show search icon in menubar if user is in search page
    if (this.router.url === '/search') {
      this.showSearchIcon = false;
    } else {
      this.showSearchIcon = true;
    }

    if (this.activatedRoute.snapshot.url.length > 0) {
      if (this.activatedRoute.snapshot.url[0].path === 'device-info') {
        this.isDebugger = true;
        this.isDeviceInfo = false;
      } else if (this.activatedRoute.snapshot.url[0].path === 'debugger') {
        this.isDebugger = false;
        this.isDeviceInfo = true;
      }
    }

    this.searchList = [
      { name: 'MAC', format: 'F0:FE:6B:69:4F:C0' },
      {
        name: 'UUID',
        format: '1110401e-55d4-4360-8273-2a199c51f7d4',
      },
      { name: 'Email', format: 'johndoe@test.com' },
      { name: 'Serial', format: '112345612312311211234567' },
    ];

    this.translate.stream('HEADER.PLACEHOLDER').subscribe((res) => {
      console.log(res);
      this.placeholderMsg = res;
    });

    this.user = this.utilService.getUsername();

    if (this.user && this.user.indexOf('@') !== -1) {
      this.user = this.user
        .substring(0, this.user.indexOf('@'))
        .replace('.', ' ');
    }

    this.searchParam = 'MAC';
    this.changePlaceholder();

    const temp = this.utilService.getTemp()
      ? this.utilService.getTemp()
      : 'celsius';
    // console.log(temp);
    this.temperature = temp;
    this.setTemperature(temp);

    this.userScope = this.utilService.getUserScope();
  }

  goToLinks(menu) {
    switch (menu) {
      case 'News & Updates':
        this.goToNews();
        break;
      case 'Search':
        this.goToSearch();
        break;
      case 'Debugger':
        this.goToDebugger();
        break;
      case 'Device Information':
        this.goToDeviceInfo();
        break;
      case 'Configuration Manager':
        this.goToCM();
        break;
      case 'Device Monitor':
        this.goToDeviceMonitor();
        break;
      default:
        break;
    }
  }

  toggle() {
    this.isSearchBar = !this.isSearchBar;
  }

  logout() {
    this.utilService.adminLogout();
  }

  goToDebugger() {
    this.router.navigate(['/debugger']);
  }

  goToDeviceInfo() {
    this.router.navigate(['/device-info']);
  }

  goToDeviceMonitor() {
    window.open(this.url);
  }

  goToSearchDeatails() {
    this.router.navigate(['/search'], {
      queryParams: {
        searchParam: this.searchParam,
        searchVal: this.searchValue,
      },
    });
  }

  manageUsers() {
    this.router.navigate(['/users']);
  }

  changePlaceholder() {
    this.placeholder = `${this.placeholderMsg} ${
      this.searchList.filter((item) => item.name === this.searchParam)[0].format
    }`;

    this.placeholder;
  }

  setTemperature(temp) {
    // console.log(`In menubar componenet: ${temp}`);
    this.utilService.setTemp(temp);
    this.tempChangeEvent.emit(temp);
  }

  goToNews() {
    this.router.navigate(['/news-updates']);
  }

  goToSearch() {
    this.router.navigate(['/search']);
  }

  goToCM() {
    this.router.navigate(['/configuration-manager']);
  }
}
