import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-device-monitor',
  templateUrl: './device-monitor.component.html',
  styleUrls: ['./device-monitor.component.scss'],
})
export class DeviceMonitorComponent implements OnInit {
  constructor(private router: Router, private utilService: UtilService) {}

  ngOnInit() {
    //  loginStatus = 1 - redirect to search
    //  else - redirect to login
    this.utilService.checkIfLoggedIn();
  }
}
