import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './smt/login/login.component';
import { ForgotPasswordComponent } from './smt/forgot-password/forgot-password.component';
import { SearchComponent } from './smt/search/search.component';
import { SearchDetailsComponent } from './smt/search-details/search-details.component';
import { DeviceInformationComponent } from './smt/device-information/device-information.component';
import { DebuggerComponent } from './smt/debugger/debugger.component';
import { DeviceMonitorComponent } from './smt/device-monitor/device-monitor.component';
import { ResetPasswordComponent } from './smt/reset-password/reset-password.component';
import { UserComponent } from './smt/user/user.component';
import { BlogComponent } from './smt/blog/blog.component';
import { BlogAdminComponent } from './smt/blog-admin/blog-admin.component';
import { ConfigurationManagerComponent } from './smt/configuration-manager/configuration-manager.component';
import { CreateConfigurationManagerComponent } from './smt/create-configuration-manager/create-configuration-manager.component';
import { UpdateConfigurationManagerComponent } from './smt/update-configuration-manager/update-configuration-manager.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'search', component: SearchComponent },
  { path: 'search-details', component: SearchDetailsComponent },
  { path: 'device-info', component: DeviceInformationComponent },
  { path: 'debugger', component: DebuggerComponent },
  { path: 'device-monitor', component: DeviceMonitorComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'users', component: UserComponent },
  { path: 'news-updates', component: BlogComponent },
  { path: 'add-news', component: BlogAdminComponent },
  { path: 'configuration-manager', component: ConfigurationManagerComponent },
  {
    path: 'create-configuration-manager',
    component: CreateConfigurationManagerComponent,
  },
  {
    path: 'update-configuration-manager',
    component: UpdateConfigurationManagerComponent,
  },

  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
