import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../core/services/auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  loginForm: FormGroup;
  loginError = false;
  loginErrorMsg = '';
  displayMsg = '';
  displayError = false;

  constructor(
    public fb: FormBuilder,
    public authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
    public utilService: UtilService
  ) {}

  ngOnInit() {
    localStorage.setItem('smtLogin', '0');
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }

  submit() {
    this.spinner.show();
    const username = this.loginForm.controls['email'].value;

    const reqBody = {
      username,
    };

    this.utilService.setUsername(username);

    // call Email API
    this.authService.forgotPassword(reqBody).subscribe(
      (res) => {
        if (res && res.hasOwnProperty('status') && res.status === 'Success') {
          this.displayMsg =
            res.hasOwnProperty('message') && res.message
              ? res.message
              : 'A confirmation code has been sent to your registered email id.';
          this.displayError = false;
          localStorage.setItem('passwordDisplayMsg', this.displayMsg);
          this.router.navigate(['/reset-password']);
        } else {
          this.displayMsg =
            res.hasOwnProperty('message') && res.message
              ? res.message
              : 'An unexpected error occurred.';
          this.displayError = true;
        }
        this.spinner.hide();
      },
      (err) => {
        this.displayMsg = 'An unexpected error occurred.';
        this.displayError = true;
        this.spinner.hide();
      }
    );
  }

  onInputChange() {
    this.displayError = false;
    this.displayMsg = '';
  }
}
