import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-chart',
  templateUrl: './modal-chart.component.html',
  styleUrls: ['./modal-chart.component.scss'],
})
export class ModalChartComponent implements OnInit {
  // @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  // lineChartType;
  // public lineChartLegend = true;
  // public lineChartPlugins = [pluginAnnotations];
  // public lineChartData: ChartDataSets[] = [];
  // public lineChartLabels: Label[] = [];
  // public lineChartOptions: ChartOptions;
  deviceID;
  constructor(
    public dialogRef: MatDialogRef<ModalChartComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {
    // console.log(this.modalData);
    // this.lineChartData = this.modalData.chartData;
    // this.lineChartLabels = this.modalData.chartLabels;
    // this.lineChartOptions = this.modalData.chartOptions;
    // this.lineChartPlugins = this.modalData.chartPlugins;
    // this.lineChartType = this.modalData.chartType;
  }

  ngOnInit() {
    this.deviceID = this.modalData.deviceId;
  }

  close() {
    this.dialogRef.close();
  }
}
