import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/services/auth.service';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  errorMessage = '';
  hidePswd = true;
  hideNewPswd = true;
  displaySuccessMsg = '';
  displayMsg = '';
  displayError = false;
  showLogin = false;
  newPassword = '';
  confirmPassword = '';

  constructor(
    public fb: FormBuilder,
    private router: Router,
    public utilService: UtilService,
    public authService: AuthService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    localStorage.setItem('smtLogin', '0');

    this.displayMsg = localStorage.getItem('passwordDisplayMsg');

    /*
      PASSWORD POLICY
        Minimum password length is 8 characters
	      Require at least one uppercase letter from Latin alphabet (A-Z)
        Require at least one lowercase letter from Latin alphabet (a-z)
        Require at least one number
        Require at least one non-alphanumeric character (! @ # $ % ^ & * ( ) _ + - = [ ] { } | ')
    */
    this.resetForm = new FormGroup({
      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(15),
        Validators.pattern(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
        ),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(15),
        Validators.pattern(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
        ),
      ]),
      confirmationCode: new FormControl('', [
        Validators.required,
        Validators.maxLength(6),
        Validators.pattern(/^\d+$/),
      ]),
    });
  }

  resetFormSubmit() {
    this.spinner.show();
    // this.displayError = false;
    // this.displayMsg = '';
    this.newPassword = this.resetForm.get('newPassword').value;
    this.confirmPassword = this.resetForm.get('confirmPassword').value;
    const confirmationCode = this.resetForm.get('confirmationCode').value;

    if (
      this.newPassword &&
      this.newPassword.trim() !== '' &&
      this.confirmPassword &&
      this.confirmPassword.trim() !== '' &&
      this.newPassword === this.confirmPassword
    ) {
      const reqBody = {
        username: this.utilService.getUsername(),
        password: this.newPassword,
        confirmationCode,
      };

      this.authService.resetPassword(reqBody).subscribe(
        (res) => {
          if (res && res.hasOwnProperty('status') && res.status === 'Success') {
            this.displayMsg =
              res.hasOwnProperty('message') && res.message
                ? res.message
                : 'Password reset successfully';
            this.displayError = false;
            this.showLogin = true;
            this.resetForm.reset();
          } else {
            this.showLogin = false;
            this.displayError = true;
            this.displayMsg =
              res.hasOwnProperty('message') && res.message
                ? res.message
                : 'An unexpected error occurred';
          }
          this.spinner.hide();
        },
        (err) => {
          this.displayError = true;
          this.showLogin = false;
          this.displayMsg =
            err.hasOwnProperty('error') &&
            err.error &&
            err.error.hasOwnProperty('message') &&
            err.error.message
              ? err.error.message
              : 'An unexpected error occurred';
          this.spinner.hide();
        }
      );
    } else {
      this.showLogin = false;
      this.displayError = true;
      this.displayMsg = 'Passwords do not match.';
      this.spinner.hide();
    }
  }

  // reset form input field validation
  // hasError(controlName: string, errorName: string) {
  //   return this.resetForm.controls[controlName].hasError(errorName);
  // }

  goToForgotPassword() {
    this.router.navigate(['/forgot-password']);
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }
}
