import {
  Component,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UtilService } from '../../core/services/util.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalComponent } from '../modal/modal.component';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public myVar = false;
  isLoggedIn: string;
  isTokenExpired = false;
  isSessionExpired = false;
  sessionCounter;
  secondsInterval;
  @Output() tempUnit = new EventEmitter<string>();
  selectLang;

  constructor(
    private utilService: UtilService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    const context = this;
    /* tslint:disable */
    // window.addEventListener('beforeunload', function (e) {
    //   context.logout();
    // });
    this.isLoggedIn = localStorage.getItem('smtLogin');

    this.secondsInterval = interval(4000);
    this.sessionCounter = this.secondsInterval
      .pipe(takeWhile(() => !this.isSessionExpired))
      .subscribe((n) => {
        // console.log('call happens here');
        this.handleSessionExpiry();
      });

    this.getLangFromStorage();
    this.storeLangInStorage(this.selectLang);
  }

  ngOnChanges() {
    this.handleSessionExpiry();
  }

  toggle() {
    this.myVar = !this.myVar;
  }

  logout() {
    this.isTokenExpired = null;
    this.utilService.adminLogout();
  }

  handleSessionExpiry() {
    let route;
    if (this.activatedRoute.snapshot.url.length > 0) {
      route = this.activatedRoute.snapshot.url[0].path;
    } else {
      route = '';
    }

    if (route !== 'login') {
      this.isTokenExpired = this.utilService.validateAuthToken(); // returns a boolean value
      if (
        this.isTokenExpired !== undefined &&
        this.isTokenExpired !== null &&
        this.isTokenExpired
      ) {
        this.isSessionExpired = true;
        const message = 'Session has expired. Please login again.';
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.data = {
          message,
        };
        this.dialog
          .open(ModalComponent, dialogConfig)
          .afterClosed()
          .subscribe((res) => {
            this.logout();
          });
      }
    }
  }

  ngOnDestroy() {
    this.isSessionExpired = null;
    this.sessionCounter.unsubscribe();
    this.secondsInterval = null;
  }

  setTemp(e) {
    // console.log(`In header component: ${e}`);
    this.tempUnit.emit(e);
  }

  goToDashboard() {
    if (this.isLoggedIn === '1') {
      this.router.navigate(['/news-updates']);
    }
  }

  setLanguage() {
    this.storeLangInStorage(this.selectLang);
    this.translate.use(this.selectLang);
  }

  storeLangInStorage(lang) {
    localStorage.setItem('language', lang);
  }

  getLangFromStorage() {
    const lang = localStorage.getItem('language');
    this.selectLang = lang || this.translate.getDefaultLang();

    this.setLanguage();
  }
}
