import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/core/services/util.service';

export interface Task {
  title: string;
  desc: string;
  btnText: string;
}

@Component({
  selector: 'app-configuration-manager',
  templateUrl: './configuration-manager.component.html',
  styleUrls: ['./configuration-manager.component.scss'],
})
export class ConfigurationManagerComponent implements OnInit {
  // taskList: Task[] = [
  //   {
  //     title: 'Create a new configuration schema',
  //     desc: `Upload a new configuration schema for device, filter and PCBA. Use
  //         this feature when to create a new SKU or create a new schema for an
  //         existing SKU.`,
  //     btnText: 'Create',
  //   },
  //   {
  //     title: 'Update existing configuration schema',
  //     desc: `Upload a configuration schema for device, filter and PCBA. Use this
  //         feature to make changes to SKUs configuration.`,
  //     btnText: 'Update',
  //   },
  // ];
  taskList: Task[] = [];
  userScope = '';
  constructor(
    private router: Router,
    private utilService: UtilService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.utilService.checkIfLoggedIn();
    this.userScope = this.utilService.getUserScope();

    this.translate.stream('CONFIG_MANAGER.TASK_LIST').subscribe((res) => {
      console.log(res);
      this.taskList = res;
    });
  }

  onClick(task) {
    if (task.btnText === 'Create') {
      this.createCM();
    } else if (task.btnText === 'Update') {
      this.updateCM();
    }
  }

  createCM() {
    this.router.navigate(['/create-configuration-manager']);
  }

  updateCM() {
    this.router.navigate(['/update-configuration-manager']);
  }
}
