import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import * as AppConfig from '../../../app-config.json';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private configuration;

  constructor(private httpClient: HttpClient) {}

  setConfig() {
    // return (this.configuration = AppConfig['default']);
    return this.httpClient
      .get('./app-config.json')
      .toPromise()
      .then((config) => (this.configuration = config));
  }

  readConfig() {
    return this.configuration;
  }
}
