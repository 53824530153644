import { Component, OnInit } from '@angular/core';
import { AppConfigService } from './core/services/app-config.service';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  config;
  constructor(
    private appConfigService: AppConfigService,
    public translate: TranslateService
  ) {
    translate.addLangs(['en', 'cn', 'kr', 'jp']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|cn|kr|jp/) ? browserLang : 'en');
  }

  ngOnInit(): void {
    console.log('In app');
    this.config = this.appConfigService.readConfig();
    console.log(this.config);
    if (this.config) {
      environment.production = this.config.production;
      environment.environmentName = this.config.environmentName;
      environment.baseURL = this.config.baseURL;
      environment.region = this.config.region;
      environment.host = this.config.host;
      environment.regionName = this.config.regionName;
    }
    console.log(environment);
  }
}
