import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { SMTModule } from './smt/smt.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { environment } from '../environments/environment';
import { ChartsModule } from 'ng2-charts';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { FilterDialogComponent } from './smt/filter-dialog/filter-dialog.component';
import { StorageServiceModule } from 'angular-webstorage-service';
// datetimepicker
// import {
//   NgxMatDatetimePickerModule,
//   NgxMatNativeDateModule,
//   NgxMatTimepickerModule,
// } from '@angular-material-components/datetime-picker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ModalComponent } from './smt/modal/modal.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppConfigService } from './core/services/app-config.service';
import { ModalChartComponent } from './smt/modal-chart/modal-chart.component';
import { QuillModule } from 'ngx-quill';
import { MatAutocompleteModule } from '@angular/material';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ModalBlogComponent } from './smt/modal-blog/modal-blog.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const appInitializerFn = (AppConfigService: AppConfigService) => {
  return () => {
    return AppConfigService.setConfig();
  };
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    // ZXingScannerModule,
    SharedModule,
    CoreModule,
    SMTModule,
    NgxPaginationModule,
    MatPaginatorModule,
    ChartsModule,
    MatInputModule,
    MatTableModule,
    MatDialogModule,
    MatMenuModule,
    StorageServiceModule,
    LoggerModule.forRoot({
      level: !environment.production ? NgxLoggerLevel.LOG : NgxLoggerLevel.OFF,
      serverLogLevel: NgxLoggerLevel.OFF,
    }),
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    // NgxMatDatetimePickerModule,
    // NgxMatTimepickerModule,
    // NgxMatNativeDateModule,
    MatPasswordStrengthModule.forRoot(),
    MatProgressBarModule,
    MatPasswordStrengthModule,
    NgxSpinnerModule,
    MatIconModule,
    MatSortModule,
    MatTooltipModule,
    MatRadioModule,
    QuillModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    FilterDialogComponent,
    ModalComponent,
    ModalChartComponent,
    ModalBlogComponent,
  ],
})
export class AppModule {}
