import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { DataService } from '../../core/services/data.service';
import { UtilService } from '../../core/services/util.service';
import { EventLogAlarmComponent } from '../event-log-alarm/event-log-alarm.component';
import { OtaHistoryComponent } from '../ota-history/ota-history.component';
import { TelemetryChartComponent } from '../telemetry-chart/telemetry-chart.component';

import { Tooltip } from '../../tooltip.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-device-information',
  templateUrl: './device-information.component.html',
  styleUrls: ['./device-information.component.scss'],
})
export class DeviceInformationComponent implements OnInit {
  // @ViewChild(OtaHistoryComponent, { static: false })
  // otaHistory: OtaHistoryComponent;
  // @ViewChild(EventLogAlarmComponent, { static: false })
  // eventLogAlarm: EventLogAlarmComponent;
  // @ViewChild(TelemetryChartComponent, { static: false })
  // telemetry: TelemetryChartComponent;
  deviceID;
  deviceDetails;
  customerDetails: any;
  productDetails: any;
  actuatorDetails = [];
  sensorDetails: any;
  lastUpdatedSensorDetails: any;
  lastUpdatedActuatorDetails;
  detailsTooltip = '';
  customerTooltip = '';
  tempValue;
  deviceSN;
  actuatorTooltips;

  constructor(
    public dataService: DataService,
    private utilService: UtilService,
    private spinner: NgxSpinnerService,
    public translate: TranslateService
  ) {
    this.translate
      .stream([
        'TOOLTIPS.details',
        'TOOLTIPS.customerInfo',
        'TOOLTIPS.ACTUATORS',
      ])
      .subscribe((res) => {
        this.detailsTooltip = res['TOOLTIPS.details'];
        this.customerTooltip = res['TOOLTIPS.customerInfo'];
        this.actuatorTooltips = res['TOOLTIPS.ACTUATORS'];
        // console.log(this.actuatorTooltips);
      });
  }

  ngOnInit() {
    //  loginStatus = 1 - redirect to search
    //  else - redirect to login
    this.utilService.checkIfLoggedIn();

    this.deviceID = this.utilService.getDeviceID();

    // get device and customer information
    this.productDetails = this.utilService.getDeviceData();

    this.deviceSN =
      this.productDetails && this.productDetails.hasOwnProperty('ds')
        ? this.productDetails.ds
        : '';

    // this.detailsTooltip = Tooltip.details;
    // this.customerTooltip = Tooltip.customerInfo;

    this.fetchAllDeviceData();
  }

  async fetchAllDeviceData() {
    this.spinner.show();
    const deviceDataPromise = this.getDeviceDetails();
    // const deviceDataResult = await deviceDataPromise;

    let values = await Promise.all([deviceDataPromise]);

    if (values && values.length > 0) {
      this.processDeviceData(values[0]);
      this.spinner.hide();
    }

    // if (
    //   deviceDataResult !== null &&
    //   this.otaHistory &&
    //   this.otaHistory.otaHistoryData !== null &&
    //   this.eventLogAlarm &&
    //   this.eventLogAlarm.eventsAlarmsLogs !== null &&
    //   this.telemetry &&
    //   this.telemetry.telemtryChartData !== null
    // ) {
    //   this.spinner.hide();
    // }

    // if (deviceDataResult !== null) {
    //   this.processDeviceData(deviceDataResult);
    // }
  }

  // fetch device and customer info
  getDeviceDetails() {
    const obj = { deviceId: this.deviceID };
    return this.dataService
      .getDeviceDetails(obj)
      .toPromise()
      .catch((e) => e);
  }

  // format received data as needed
  processDeviceData(objDeviceData) {
    this.customerDetails = {};
    this.deviceDetails = {};
    this.actuatorDetails = [];
    this.sensorDetails = [];

    if (
      objDeviceData.hasOwnProperty('customerinfo') &&
      objDeviceData['customerinfo'] !== null
    ) {
      this.customerDetails = objDeviceData['customerinfo'];
    }

    if (
      objDeviceData.hasOwnProperty('devicedetails') &&
      objDeviceData['devicedetails'] !== null
    ) {
      this.deviceDetails = objDeviceData['devicedetails'];
    }

    if (
      objDeviceData.hasOwnProperty('actuatorsinfo') &&
      objDeviceData['actuatorsinfo'] !== null
    ) {
      this.actuatorDetails = Object.entries(objDeviceData['actuatorsinfo'])
        .map((obj) => {
          return {
            name: obj[0],
            value: obj[1],
          };
        })
        .filter((obj) => {
          if (obj.name === 'timestamp') {
            this.lastUpdatedActuatorDetails = obj.value;
          } else {
            /**** All this processing is done at the UI instead of the back-end. This is not best practices but for now this will have to do ****/
            // split names found in the array
            const namesToSplit = [
              { oldVal: 'filterusage', newVal: 'filter usage' },
              { oldVal: 'fanspeed', newVal: 'fan speed' },
              { oldVal: 'safetyswitch', newVal: 'door' },
              { oldVal: 'automode', newVal: 'auto mode' },
              { oldVal: 'nightmode', newVal: 'night mode' },
            ];

            namesToSplit.forEach((o) => {
              if (o.oldVal === obj.name) {
                obj.name = o.newVal;
              }
            });

            // change true/false to on/off
            if (obj.value === 'true') {
              obj.value = 'ON';
            } else if (obj.value === 'false') {
              obj.value = 'OFF';
            }

            // have to add units as well ... more processing :( :(
            if (obj.name === 'filter usage') {
              obj.value += '% (Consumed)';
            }

            if (obj.name === 'brightness') {
              obj.value += '%';
            }

            if (obj.name === 'door') {
              obj.value = obj.value === 'ON' ? 'CLOSED' : 'OPEN';
            }

            //  add tooltip for actuators
            // for (const [key, value] of Object.entries(Tooltip)) {
            for (const [key, value] of Object.entries(this.actuatorTooltips)) {
              if (obj.name === key) {
                obj['tooltip'] = value;
              }
            }
            return obj;
          }
        });
    }

    if (
      objDeviceData.hasOwnProperty('sensorsinfo') &&
      objDeviceData['sensorsinfo'] !== null
    ) {
      this.sensorDetails = objDeviceData['sensorsinfo'];
      this.lastUpdatedSensorDetails = objDeviceData.sensorsinfo[0].t;

      //  add tooltips here
      this.sensorDetails.map((sensor) => {
        if (sensor.n === 't') {
          sensor.n = 'Temperature';

          // value is in celsius initially
          if (this.tempValue === 'fahrenheit') {
            const val = (sensor.v * 9) / 5 + 32;
            sensor.v = `${val} ${this.tempValue}`;
          } else {
            sensor.v += ' celsius';
          }
        } else if (sensor.n === 'h') {
          sensor.n = 'Humidity';
          sensor.v += '%';
        } else if (sensor.n.includes('pm')) {
          sensor.n = sensor.n.toUpperCase();
          sensor.v += ' ug/m3';
        } else if (sensor.n === 'tVOC') {
          sensor.v += ' ppb';
        } else if (sensor.n === 'fsp0') {
          sensor.n = 'Fan Speed';
        }

        //  add tooltip for sensors
        // for (const [key, value] of Object.entries(Tooltip)) {
        for (const [key, value] of Object.entries(this.actuatorTooltips)) {
          if (sensor.n.toLowerCase() === key.toLowerCase()) {
            sensor['tooltip'] = value;
          }
        }
      });
    }
  }

  setTempValue(tempValue) {
    this.tempValue = tempValue;

    if (this.sensorDetails && this.sensorDetails.length > 0) {
      this.sensorDetails.map((sensor) => {
        if (sensor.n === 'Temperature') {
          sensor['oldV'] = sensor.v;
          const arr = sensor.v.split(' ');
          if (tempValue.toLowerCase() === arr[1].toLowerCase()) {
            return;
          } else {
            let val;
            if (tempValue === 'fahrenheit') {
              val = (arr[0] * 9) / 5 + 32;
            } else {
              val = ((arr[0] - 32) * 5) / 9;
            }
            sensor.v = `${val} ${tempValue}`;
          }
        }
      });
    }
  }

  // refresh device information page
  async refreshDeviceInfo() {
    this.spinner.show();
    const deviceDataResult = await this.getDeviceDetails();

    this.processDeviceData(deviceDataResult);

    this.spinner.hide();
  }
}
