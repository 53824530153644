import { Component, OnChanges, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/core/services/data.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-blog-admin',
  templateUrl: './blog-admin.component.html',
  styleUrls: ['./blog-admin.component.scss'],
})
export class BlogAdminComponent implements OnInit {
  form: FormGroup = this.fb.group({
    html: new FormControl(),
    blogTitle: new FormControl('', [Validators.required]),
  });
  mode;
  newsItem;
  emptyMsg = '';

  public editorOptions = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      // [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      // [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }], // dropdown with defaults from theme
      // [{ font: [] }],
      // [{ align: [] }],

      ['clean'], // remove formatting button
      ['link'],
      // ['link', 'image', 'video'], // link and image, video
    ],
  };

  constructor(
    private router: Router,
    public utilService: UtilService,
    public dataService: DataService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    public translate: TranslateService
  ) {
      this.translate.stream("NEWS_UPDATES_ADMIN.ADD").subscribe((res) => {
        this.mode = res;
        });
  }

  ngOnInit() {
    this.utilService.checkIfLoggedIn();

    this.newsItem = localStorage.getItem('editBlog');
    // console.log(this.newsItem);
    if (this.newsItem !== null && this.newsItem.trim() !== '') {
      this.newsItem = JSON.parse(this.newsItem);
      // console.log(this.newsItem);
      this.newsItem['description'] = this.isBase64(this.newsItem['description'])
        ? atob(this.newsItem['description'])
        : this.newsItem['description'];
      // // newsItem.description = atob(newsItem.description);
      this.newsItem['editable'] = true;
      // // this.clickNewsItem(newsItem);
      this.form.controls['html'].setValue(this.newsItem['description']);
      this.form.controls['blogTitle'].setValue(this.newsItem['subject']);
      //this.mode = 'update';
      this.translate.stream('NEWS_UPDATES_ADMIN.UPDATE').subscribe((res) => {
        this.mode = res;
        });
    }

    // this.onChanges();
    this.translate.stream('NEWS_UPDATES_ADMIN.EMPTY_MSG').subscribe((res) => {
      // console.log(res);
      this.emptyMsg = res;
    });
  }

  isBase64(str) {
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  }

  addBlog() {
    this.spinner.show();
    const subject = this.form.get('blogTitle').value;
    const description = this.form.get('html').value;

    if (
      subject &&
      subject.trim() !== '' &&
      description &&
      description.trim() !== ''
    ) {
      const reqBody = {
        subject,
        // description: btoa(description),
        description: btoa(
          description.replace(/[\u00A0-\u2666]/g, function (c) {
            return '&#' + c.charCodeAt(0) + ';';
          })
        ),
        uid: this.utilService.getUsername(),
        action: 'create',
      };

      // console.log(JSON.stringify(reqBody));

      this.dataService.blog(reqBody).subscribe(
        (res) => {
          // console.log(res);
          if (
            res &&
            res.hasOwnProperty('StatusCode') &&
            res.StatusCode === 200
          ) {
            const message = res.hasOwnProperty('Message')
              ? res.Message
              : 'Blog posted successfully';

            this.openModalPopUp(message);
            this.form.reset();
            Object.keys(this.form.controls).forEach((key) => {
              this.form.get(key).setErrors(null);
            });
          } else {
            const message = res.hasOwnProperty('Message')
              ? res.Message
              : 'An unexpected error occurred.';
            this.handleError(message);
          }
          this.spinner.hide();
        },
        (err) => {
          this.handleError(err);
          this.spinner.hide();
        }
      );
    } else {
      this.spinner.hide();
      const message = this.emptyMsg; // 'News title and description cannot be empty.';
      this.openModalPopUp(message);
    }
  }

  close() {
    localStorage.setItem('editBlog', '');
    this.router.navigate(['/news-updates']);
  }

  // update the blog
  updateBlog() {
    // console.log(this.newsItem);
    this.spinner.show();
    let blog = { description: '', subject: '', uid: '' };
    blog.description = this.form.get('html').value;
    blog.subject = this.form.get('blogTitle').value;
    blog.uid = this.utilService.getUsername();
    const { subject, description, uid } = blog;
    const reqBody = {
      subject,
      uuid: this.newsItem.uuid,
      description: btoa(
        description.replace(/[\u00A0-\u2666]/g, function (c) {
          return '&#' + c.charCodeAt(0) + ';';
        })
      ),
      uid,
      action: 'update',
    };

    // console.table(reqBody);

    this.dataService.blog(reqBody).subscribe(
      (res) => {
        if (res && res.hasOwnProperty('StatusCode') && res.StatusCode === 200) {
          const message = res.hasOwnProperty('Message')
            ? res.Message
            : 'Blog updated successfully';

          this.openModalPopUp(message);
        } else {
          const message = res.hasOwnProperty('Message')
            ? res.Message
            : 'An unexpected error occurred.';
          this.handleError(message);
        }
        this.spinner.hide();
      },
      (err) => {
        this.handleError(err);
        this.spinner.hide();
      }
    );
  }

  // handle error
  handleError(error) {
    if (error) {
      this.openModalPopUp(error);
    }
  }

  // modal pop-ups for showing warning/error messaages
  openModalPopUp(bodyText) {
    if (bodyText) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        message: bodyText,
      };

      this.dialog.open(ModalComponent, dialogConfig);
    }
  }

  onChanges(): void {
    this.form.statusChanges.subscribe((val) => {
      console.log(val);
      console.log(this.form.status);
    });
  }
}
