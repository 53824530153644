import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AuthService } from '../../core/services/auth.service';
import { DataService } from '../../core/services/data.service';
import { UtilService } from '../../core/services/util.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TimeoutError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginErrorMsg = '';
  error: any;
  showspinner: boolean;
  loginError = false;
  regionName = environment.regionName;

  constructor(
    public fb: FormBuilder,
    public authService: AuthService,
    private router: Router,
    public dataService: DataService,
    private utilService: UtilService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    //  loginStatus = 1 - redirect to search
    //  else - redirect to login
    // const loginStatus = localStorage.getItem('smtLogin');
    // if (loginStatus === '1') {
    //   this.router.navigate(['/search']);
    // } else {
    // localStorage.setItem('smtLogin', '0');
    // this.utilService.adminLogout();
    // }

    localStorage.setItem('smtLogin', '0');
    localStorage.setItem('temp', 'celsius');
    sessionStorage.setItem('userScope', '');
    sessionStorage.setItem('username', '');
    sessionStorage.setItem('access_token', '');
    localStorage.setItem('passwordDisplayMsg', '');
    sessionStorage.setItem('deviceID', '');
    sessionStorage.setItem('productData', '');
    sessionStorage.setItem('telemetryData', '');

    this.loginForm = this.fb.group({
      userName: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  // used in html for checking errors in the input fields.
  get fLogin() {
    return this.loginForm.controls;
  }

  forgotPassword() {
    this.router.navigate(['/forgot-password']);
  }

  // on clicking submit
  async loginFormSubmit() {
    // console.log(this.loginForm.controls);
    const userName = this.loginForm.controls
      ? this.loginForm.controls['userName'].value
      : '';
    const password = this.loginForm.controls
      ? this.loginForm.controls['password'].value
      : '';

    // clear loginerror message
    this.loginErrorMsg = '';
    this.loginError = false;

    if (
      this.loginForm.valid &&
      userName !== '' &&
      !userName.toLowerCase().includes('<script>') &&
      password !== '' &&
      !password.toLowerCase().includes('<script>')
    ) {
      this.spinner.show();

      const authenticationResult = await this.authService
        .signIn(userName, password)
        .toPromise()
        .catch((e) => e);

      if (
        authenticationResult &&
        authenticationResult.hasOwnProperty('access_token') &&
        authenticationResult.access_token !== '' &&
        authenticationResult.access_token !== null
      ) {
        this.utilService.setAccessToken(authenticationResult.access_token);
        this.utilService.setUsername(userName);

        const req = { uuid: userName };
        this.dataService.getUserScope(req).subscribe(
          (res) => {
            if (
              res &&
              res !== null &&
              res !== undefined &&
              res.length > 0 &&
              res[0].hasOwnProperty('scope') &&
              res[0].scope
            ) {
              const scope = res[0].scope;
              this.utilService.setUserScope(scope);
              localStorage.setItem('smtLogin', '1');
              this.loginError = false;
              this.loginErrorMsg = '';
              if (this.regionName === 'Frankfurt') {
                this.router.navigate(['/configuration-manager']);
              } else {
                this.router.navigate(['/news-updates']);
              }

              this.spinner.hide();
            } else {
              this.spinner.hide();
              this.handleError('');
            }
          },
          (err) => {
            this.spinner.hide();
            this.handleError('');
          }
        );
      } else {
        this.handleError(authenticationResult);
      }
    } else {
      this.handleError('');
    }
  }

  handleError(err) {
    if (
      err instanceof TimeoutError ||
      (err &&
        typeof err === 'string' &&
        err.toLowerCase() === 'timeout exception')
    ) {
      this.loginErrorMsg = 'Request timed out. Please try again.';
    } else if (err.hasOwnProperty('status') && err.status === 0) {
      this.loginErrorMsg = 'Unable to reach the server. Please try again.';
    } else if (err.hasOwnProperty('error')) {
      let errMsgKey;

      errMsgKey = Object.keys(err.error);
      this.loginErrorMsg =
        errMsgKey.length > 0
          ? err.error[errMsgKey[0]]
            ? err.error[errMsgKey[0]]
            : 'Unable to reach the server. Please try again.'
          : 'Unable to reach the server. Please try again.';
    } else {
      this.loginErrorMsg = 'Unable to reach the server. Please try again.';
    }
    this.spinner.hide();
    this.loginError = true;
  }
}
