import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import 'chartjs-plugin-zoom';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

import { DataService } from 'src/app/core/services/data.service';
import { ModalComponent } from '../modal/modal.component';
import { ExcelService } from 'src/app/core/services/excel.service';
import * as Chart from 'chart.js';

import { Tooltip } from '../../tooltip.enum';
import { ModalChartComponent } from '../modal-chart/modal-chart.component';
import { UtilService } from 'src/app/core/services/util.service';
import { TranslateService } from '@ngx-translate/core';

var selectedLegends = [];
var dummy = [];
@Component({
  selector: 'app-telemetry-chart',
  templateUrl: './telemetry-chart.component.html',
  styleUrls: ['./telemetry-chart.component.scss'],
})
export class TelemetryChartComponent implements OnInit, OnDestroy {
  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
  @Input() deviceID: string;
  @Input() isShowFullScreenIcon: string;
  @Input() data = [];

  graphsfilterform: FormGroup;
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{}],
      yAxes: [{}],
    },
    legend: {
      position: 'bottom',
      display: true,
      align: 'center',
      labels: {
        fontColor: '#000',
        boxWidth: 40,
        fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        padding: 10,
      },

      onClick: function (e, legendItem) {
        var index = legendItem.datasetIndex;
        var ci = this.chart;
        var meta = ci.getDatasetMeta(index);

        // See controller.isDatasetVisible comment
        meta.hidden =
          meta.hidden === null ? !ci.data.datasets[index].hidden : null;

        const legendText = legendItem.text; // legend name

        // mark hidden as true for legends which are hidden in legends array
        selectedLegends.map((o) => {
          if (legendText === o.legendName) {
            o.hidden = meta.hidden;
          }
        });

        dummy = selectedLegends.slice();
        console.log(dummy);

        // We hid a dataset ... rerender the chart
        ci.update();
      },
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          enabled: true,
          mode: 'x',
        },
      },
    },
  };
  errorMsg: string;
  showError: boolean;
  telemtryChartData: any;
  tooltip = '';
  filteredListArr = [];
  sensorData;
  daysInMonth = 30;
  hoursLessOne = 23;
  minsLessOne = 59;
  allReadingsText = '';
  invalidDateRangeMsg = '';
  noDataMsg = '';
  noDateRangeMsg = '';
  requiredDateRangeMsg = '';

  constructor(
    private spinner: NgxSpinnerService,
    public dataService: DataService,
    public dialog: MatDialog,
    private excelService: ExcelService,
    public utilService: UtilService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    const now = moment();
    const telemetryChartFromDate = moment().startOf('month').format();
    const telemetryChartToDate = moment().endOf('month').format();

    this.graphsfilterform = new FormGroup({
      graphSearchFrom: new FormControl(telemetryChartFromDate, [
        Validators.required,
      ]),
      graphSearchTo: new FormControl(telemetryChartToDate, [
        Validators.required,
      ]),
    });

    // this.tooltip = Tooltip.telemetry;

    this.translate
      .stream([
        'INVALID_DATE_RANGE_MSG',
        'NO_DATA_MSG',
        'DATE_RANGE_REQUIRED_MSG',
        'NO_DATE_RANGE_MSG',
        'TOOLTIPS.telemetry',
      ])
      .subscribe((res) => {
        // console.log(res);
        this.invalidDateRangeMsg = res['INVALID_DATE_RANGE_MSG'];
        this.noDataMsg = res['NO_DATA_MSG'];
        this.requiredDateRangeMsg = res['DATE_RANGE_REQUIRED_MSG'];
        this.noDateRangeMsg = res['NO_DATE_RANGE_MSG'];
        this.tooltip = res['TOOLTIPS.telemetry'];
      });

    if (this.data && this.data.length > 0) {
      this.telemtryChartData = this.data.slice();
      this.renderChart(this.telemtryChartData);
    } else {
      this.loadData();
    }
  }

  ngOnDestroy() {
    this.utilService.clearTelemetryData();
  }

  async loadData(value = null) {
    this.spinner.show();

    const telemetryDataPromise = this.getTelemetryChartData(value);
    const telemetryResponse = await telemetryDataPromise;
    // if (this.telemtryChartData !== null && this.telemtryChartData.length > 0) {
    if (
      telemetryResponse !== null &&
      telemetryResponse.hasOwnProperty('status') &&
      telemetryResponse.status === 'Success' &&
      telemetryResponse.hasOwnProperty('data') &&
      telemetryResponse.data.length > 0
    ) {
      this.telemtryChartData = telemetryResponse.data;
      this.renderChart(this.telemtryChartData);
    } else {
      this.errorMsg = 'No Data Found';
      this.showError = true;
      this.lineChartData = [];
      this.spinner.hide();
    }
  }

  // call API to fetch telemetry data
  getTelemetryChartData(value = null) {
    let fromDate = null,
      toDate = null;

    fromDate = this.graphsfilterform.get('graphSearchFrom').value;
    toDate = this.graphsfilterform.get('graphSearchTo').value;

    if (this.graphsfilterform.valid && fromDate !== '' && toDate !== '') {
      this.errorMsg = '';
      this.showError = false;

      this.allReadingsText = `${moment(fromDate).format(
        'DD MMM YYYY HH:mm:ss'
      )} - ${moment(toDate).format('DD MMM YYYY HH:mm:ss')}`;

      fromDate = new Date(fromDate);
      const momentFromDate = moment(fromDate).format('YYYY-MM-DD');

      toDate = new Date(toDate);
      const momentToDate = moment(toDate).format('YYYY-MM-DD');

      if (moment(momentToDate).isSameOrAfter(momentFromDate)) {
        if (value !== 'hour') {
          fromDate.setHours(0, 0, 0, 0);
          toDate.setHours(this.hoursLessOne, this.minsLessOne, 0, 0);
        }
        fromDate = moment.utc(fromDate).format('X');
        toDate = moment.utc(toDate).format('X');

        // console.log(fromDate);
        // console.log(toDate);

        const obj = {
          startDate: fromDate,
          endDate: toDate,
          deviceID: this.deviceID,
        };

        return this.dataService
          .getTelemetryChartData(obj)
          .toPromise()
          .catch((e) => e);
      } else {
        this.spinner.hide();
        const message = this.invalidDateRangeMsg; // `Invalid date range. Please enter correct date.`;
        this.openModalPopUp(message);
        return;
      }
    } else {
      this.spinner.hide();
      const message = this.noDateRangeMsg; // `Date range is not selected.`;
      this.openModalPopUp(message);
      return;
    }
  }

  // function to plot the chart
  renderChart(telemtryChartData) {
    this.spinner.show();

    console.log(selectedLegends);
    console.log(dummy);

    selectedLegends = [];
    this.lineChartData = [];
    let sensorValArr = [];
    let sensorNamesArr = [];
    let chartLabels = [];
    this.showError = false;

    const arrLabelsTemp = [];

    // get payload and save it in an array after parsing it
    const arrPayload = telemtryChartData.map((obj) => {
      return obj.payload;
    });

    // this is x-axis => timestamps/dates
    chartLabels = telemtryChartData.map((obj) => {
      const x = obj.timestamp * 1000;
      return moment(x).format('YYYY/MM/DD HH:mm:ss');
    });

    this.lineChartLabels = chartLabels.filter((val, index) => {
      return chartLabels.indexOf(val) === index;
    });

    // Get distinct sensor names from the payload
    arrPayload.forEach((val) => {
      val.map((obj) => {
        arrLabelsTemp.push(obj.n); // temp array contains duplicate sensor names
      });
    });

    sensorNamesArr = arrLabelsTemp.filter((val, index) => {
      return arrLabelsTemp.indexOf(val) === index;
    });
    // END Get distinct sensor names from the payload

    const temparray = [];
    // loop over sensor names array to get data for each sensor and finally create chart data
    sensorNamesArr.forEach((label) => {
      sensorValArr = [];
      arrPayload.forEach((val) => {
        val.forEach((v, i) => {
          if (v.n === label) {
            sensorValArr.push(v.v);
          }
        });
      });
      temparray.push({
        data: sensorValArr,
        label,
      });

      selectedLegends.push({ legendName: label, hidden: null }); // legends list and their property - hidden
    });

    this.lineChartData = temparray;

    this.spinner.hide();
  }

  // compare data between two dates and return data
  filteredList(fromDate, toDate, arrFinal) {
    let filteredByDateRange;
    filteredByDateRange = arrFinal.filter((obj) => {
      const d1 = new Date(obj['Date time'] * 1000);
      let m1 = '' + (d1.getMonth() + 1);
      let day = '' + d1.getDate();
      // const mStr = '';
      if (m1.length < 2) {
        m1 = '0' + m1;
      }
      if (day.length < 2) {
        day = '0' + day;
      }
      const dateStr = d1.getFullYear() + '-' + m1 + '-' + day;

      return (
        moment(dateStr).isSameOrAfter(fromDate) &&
        moment(dateStr).isSameOrBefore(toDate)
      );
    });

    return filteredByDateRange;
  }

  exportToExcel(event) {
    event.preventDefault();
    let cloneTelemetryData = [];
    // clone array by value and NOT reference
    cloneTelemetryData = JSON.parse(JSON.stringify(this.telemtryChartData)); //!! this needs to change!!

    // get the values in array in a proper format
    const arrTemp = cloneTelemetryData
      .map((item) => {
        return { payload: item.payload, timestamp: item.timestamp };
      })
      .map((arr) => {
        arr.payload.map(
          (o) => (
            (o['Date time'] = arr.timestamp),
            (o['Sensor'] = o['n']),
            (o['Measure'] = o['v']),
            delete o['n'],
            delete o['v']
          )
        );
        return arr;
      })
      .map((object) => {
        return [...object.payload];
      })
      .flat(); // flatten the array
    // EOF

    // filter array based on sensors chosen
    // TODO 1. sensors chosen will have property hidden = null
    // TODO 2. outer loop over each sensor array and inner loop over main array and then filter
    let arrFinal = [];
    selectedLegends.map((sensor) => {
      if (!sensor.hidden) {
        arrFinal.push(
          arrTemp.filter((sensordata) => {
            if (sensor.legendName === sensordata.Sensor) {
              return sensordata;
            }
          })
        );
      }
    }); // EOF

    arrFinal = arrFinal.flat(); // flatten the array

    // sort array in desc order - newest sensor value should be on top
    arrFinal = arrFinal.sort((a, b) => b['Date time'] - a['Date time']);

    // filter the remaining array based on dates selected
    let fromDate = this.graphsfilterform.get('graphSearchFrom').value;
    let toDate = this.graphsfilterform.get('graphSearchTo').value;

    if (fromDate && toDate) {
      fromDate = new Date(fromDate);
      fromDate = moment(fromDate).format('YYYY-MM-DD');

      toDate = new Date(toDate);
      toDate = moment(toDate).format('YYYY-MM-DD');

      // check whether to date is greater than or equal to from date
      if (moment(toDate).isSameOrAfter(fromDate)) {
        this.filteredListArr = this.filteredList(fromDate, toDate, arrFinal); // call method to retrieve array between dates selected
        if (this.filteredListArr.length > 0) {
          // format date in the filtered array
          this.filteredListArr.map((ele: any) => {
            ele['Date time'] = moment(ele['Date time'] * 1000).format(
              'YYYY-MM-DD HH:mm'
            );
            return ele;
          });

          this.excelService.exportAsExcelFile(
            this.filteredListArr,
            'Sensor Data'
          );
        } else {
          const message = this.noDataMsg; // `There is no data present within the date range and sensors selected.`;
          this.openModalPopUp(message);
        }
      } else {
        const message = this.noDateRangeMsg; // `Date is required for exporting data to excel.`;
        this.openModalPopUp(message);
      }
    }
  }

  openModalPopUp(bodyText) {
    if (bodyText) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        message: bodyText,
      };
      this.dialog.open(ModalComponent, dialogConfig);
    }
  }

  openInFullScreen() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig = {
      data: { deviceId: this.deviceID, data: this.telemtryChartData },
      height: '90vh',
      width: '80vw',
    };

    this.dialog.open(ModalChartComponent, dialogConfig);
  }

  onTimeSpanClick(value) {
    let startTime = null,
      endTime = null;

    switch (value) {
      case 'day':
        // start of the current date
        startTime = moment().startOf('day').format('X');
        // end of the current date
        endTime = moment().endOf('day').format('X');
        break;
      case 'week':
        // week starts from Monday
        // start of the current week - Mon
        startTime = moment().startOf('isoWeek').format('X');
        // end of the current week - Sun
        endTime = moment().endOf('isoWeek').format('X');
        break;
      case 'month':
        // start of the current month
        startTime = moment().startOf('month').format('X');
        // end of the current month
        endTime = moment().endOf('month').format('X');
        break;
      case 'hour':
        // start of the current hour
        startTime = moment().startOf('hour').format('X');
        // end of the current hour
        endTime = moment().endOf('hour').format('X');
        break;
      default:
        break;
    }

    // console.log(startTime);
    // console.log(endTime);

    if (startTime !== null && endTime !== null) {
      const fromDate = moment.unix(startTime).format();
      const toDate = moment.unix(endTime).format();

      console.log(fromDate);
      console.log(toDate);

      this.graphsfilterform.controls['graphSearchFrom'].setValue(fromDate);
      this.graphsfilterform.controls['graphSearchTo'].setValue(toDate);

      this.loadData(value);
    }
  }
}
