import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';
import { ExcelService } from './services/excel.service';
import { UtilService } from './services/util.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, SharedModule],
  exports: [],
  providers: [AuthService, DataService, ExcelService, UtilService],
})
export class CoreModule {}
