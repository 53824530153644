import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/core/services/data.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ModalComponent } from '../modal/modal.component';

export interface UserData {
  id: string;
  email: string;
  action: string;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit, AfterViewInit {
  userForm: FormGroup;
  userList;
  displayedColumns: string[] = ['id', 'email', 'scope', 'action'];
  dataSource: MatTableDataSource<UserData>;
  filter = '';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  userRole = new FormControl('admin', [Validators.required]);
  pattern;
  username;
  userNotFoundMsg = '';
  invalidEmail = '';
  constructor(
    public dataService: DataService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private utilService: UtilService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.utilService.checkIfLoggedIn();

    this.userList = [];

    this.userForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      // userRole: new FormControl('admin', [Validators.required]),
    });

    this.translate
      .stream(['NOT_FOUND_ERR', 'INVALID_EMAIL'])
      .subscribe((res) => {
        console.log(res);
        this.userNotFoundMsg = res['NOT_FOUND_ERR'];
        this.invalidEmail = res['INVALID_EMAIL'];
      });
  }

  ngAfterViewInit() {
    // fetch user list
    this.getUsersList();

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
  }

  getUsersList() {
    this.spinner.show();

    this.dataService.getUsers().subscribe(
      (res) => {
        if (res && res !== null && res.length > 0) {
          this.userList = res;
          this.userList.map((user, index) => {
            user.id = index + 1;
            user.action = 'delete';
            user.email = user.uuid;
            return user;
          });
          // console.log(this.userList);
          this.dataSource = new MatTableDataSource(this.userList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  hasError = (controlName: string, errorName: string) => {
    return this.userForm.controls[controlName].hasError(errorName);
  };

  // adddelete user
  manageUser(action, user) {
    this.spinner.show();
    if (action === 'create') {
      this.username = this.userForm.get('username').value;

      this.pattern =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!this.pattern.test(this.username)) {
        this.spinner.hide();
        this.openModalPopUp(this.invalidEmail, false);
        return false;
      }
    } else {
      this.username = user;
    }

    const scope =
      this.userRole.value.toLowerCase() === 'admin' ? 'sm' : 'sm-user';

    const request = {
      username: this.username,
      action,
      scope,
    };

    this.dataService.manageUser(request).subscribe(
      (res) => {
        this.spinner.hide();

        if (
          res &&
          res !== null &&
          res !== undefined &&
          res.hasOwnProperty('status') &&
          res.status === 200
        ) {
          if (res.hasOwnProperty('body') && res.body !== null) {
            if (res.body.hasOwnProperty('message')) {
              this.openModalPopUp(res.body.message, false);
            } else {
              const message = `User ${this.username} is successfully registered \nin SMT portal. Following are the credentials:\n  username: ${this.username},\n password: ${res.body.password}`;
              this.openModalPopUp(message, true);
            }
            this.userForm.reset();
          } else {
            this.openModalPopUp(this.userNotFoundMsg, false);
          }
        } else {
          this.openModalPopUp('An unexpected error occurred.', false);
        }
      },
      (err) => {
        this.spinner.hide();
        console.log(err);
        // err.message ||
        const message = 'An unexpected error occurred.';
        this.openModalPopUp(message, false);
      }
    );
  }

  // modal pop-ups for showing warning/error messaages
  openModalPopUp(bodyText, isPreTag) {
    if (bodyText) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        message: bodyText,
        isPreTag,
      };
      this.dialog
        .open(ModalComponent, dialogConfig)
        .afterClosed()
        .subscribe((res) => {
          // console.log(res);
          this.dataSource.filter = '';
          this.filter = '';
          this.getUsersList();
        });
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
